import {Component} from '@angular/core';
import * as XLSX from 'xlsx';
import {Utils} from '../../service/utils';
import {DataService} from '../../service/data.service';
import {ToastrService} from 'ngx-toastr';
import {VideoResponse} from '../../models/GetVideoResponseModel';
import {catchError, throwError} from 'rxjs';

@Component({
  selector: 'app-bulk-upload-videos',
  templateUrl: './bulk-upload-videos.component.html',
  styleUrls: ['./bulk-upload-videos.component.css']
})
export class BulkUploadVideosComponent {
  videoResponses: VideoResponse[] = [];
  data: any[][] | undefined;
  isLoading = false;
  errorMessage: string = '';
  pageLimit: number = 30;
  currentPage = 1;
  totalPages = 1;
  statesMap: Map<string, string>;
  languagesMap: Map<string, string>;
  cropsMap: Map<number, string>;
  missingFields: string[][] = [];

  constructor(private utils: Utils,
              private dataservice: DataService,
              private toastrService: ToastrService) {
    this.statesMap = utils.statesMap;
    this.languagesMap = utils.languagesMap;
    this.cropsMap = utils.cropsMap;
  }

  onFileChange(event: any): void {
    const target: DataTransfer = <DataTransfer>(event.target);
    if (target.files.length !== 1) {
      this.errorMessage = 'Please upload a single file.';
      return;
    }
    this.utils.showSpinner();
    const reader: FileReader = new FileReader();
    reader.onload = (e: any) => {
      const bstr: string = e.target.result;
      const wb: XLSX.WorkBook = XLSX.read(bstr, {type: 'binary'});
      const wsname: string = wb.SheetNames[0];
      const ws: XLSX.WorkSheet = wb.Sheets[wsname];
      this.data = XLSX.utils.sheet_to_json(ws, {header: 1}) as any[][];
      this.processExcelData();
    };
    reader.readAsBinaryString(target.files[0]);
  }

  processExcelData(): void {
    if (!this.data || this.data.length <= 1) {
      this.errorMessage = 'No data available in the uploaded file.';
      this.utils.hideSpinner();
      return;
    }

    const headers = this.data[0];
    this.totalPages = Math.ceil((this.data.length - 1) / this.pageLimit);
    this.videoResponses = [];
    this.missingFields = []; // Initialize missingFields for each upload

    const start = (this.currentPage - 1) * this.pageLimit + 1; // Skip header row
    const end = Math.min(start + this.pageLimit, this.data.length);

    for (let i = start; i < end; i++) {
      const row = this.data[i];
      const getValue = (value: any): string => value ? value.toString().trim() : '';

      const videoResponse: VideoResponse = {
        id: null,
        form: getValue(row[1]).toUpperCase(),
        title: getValue(row[2]),
        url: getValue(row[3]),
        languageIso: getValue(row[4]),
        state: getValue(row[5])
          ? getValue(row[5]).split(',')
            .map((s: string) => s.trim())
            .map((stateName: string) => {
              const stateKey = Array.from(this.statesMap.entries())
                .find(([key, value]) => value === stateName)?.[0];
              return stateKey || stateName;
            })
          : [],
        crop: getValue(row[6]) ? getValue(row[6]).split(',').map((c: string) => c.trim()) : [],
        publishedDate: row[7] ? new Date((row[7] - 25569) * 86400000) : new Date(),
        type: getValue(row[8]) ? getValue(row[8]).split(',').map((t: string) => t.trim()) : [],
        focusArea: getValue(row[9]) ? getValue(row[9]).split(',').map((t: string) => t.trim()) : [],
        tags: getValue(row[10]),
        creatorName: getValue(row[11]),
        channelUrl: getValue(row[12]),
        channelName: getValue(row[13]),
        description: getValue(row[14])
      };

      // Validation checks
      const missing: string[] = [];
      if (!videoResponse.url) missing.push('URL');
      if (!videoResponse.title) missing.push('Title');
      if (!videoResponse.form) missing.push('Form');
      if (!videoResponse.languageIso) missing.push('Language');
      if (!videoResponse.publishedDate) missing.push('Published Date');
      if (!videoResponse.type.length) missing.push('Type');
      if (!videoResponse.focusArea.length) missing.push('Focus Area');
      if (!videoResponse.channelName) missing.push('Channel Name');
      if (!videoResponse.channelUrl) missing.push('Channel Link');

      this.missingFields.push(missing);
      if (missing.length > 0)
        this.toastrService.warning(`Missing fields in row ${i}: ${missing.join(', ')}`);

      this.videoResponses.push(videoResponse);
    }

    this.utils.hideSpinner();
    this.errorMessage = '';
  }

  uploadData(fileInput: HTMLInputElement): void {
    // Check for missing fields before uploading
    const hasMissingFields = this.missingFields.some(missing => missing.length > 0);

    if (hasMissingFields) {
      this.toastrService.warning('Please resolve missing fields before uploading.');
      return;
    }

    if (this.videoResponses.length > 0) {
      this.utils.showSpinner();
      this.dataservice.uploadVideos(this.videoResponses).pipe(
        catchError(err => {
          console.error(err);
          this.utils.hideSpinner();
          const responseCode = err.error?.metaData?.responseCode;
          this.toastrService.error(`Videos not uploaded. ${responseCode}`);
          return throwError(err);
        })
      ).subscribe(
        (response: any) => {
          this.utils.hideSpinner();
          this.toastrService.success(response.message);
          this.videoResponses = [];
          fileInput.value = '';
        }
      );
    } else {
      this.toastrService.warning('No video data to upload.');
    }
  }

  getMappedStates(states: string[]): string {
    return states.map(state => this.statesMap.get(state) || state).join(', ');
  }

  previousPage(): void {
    if (this.currentPage > 1) {
      this.currentPage--;
      this.processExcelData();
    }
  }

  nextPage(): void {
    if (this.currentPage < this.totalPages) {
      this.currentPage++;
      this.processExcelData();
    }
  }
}
